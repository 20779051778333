import gql from 'graphql-tag'

// title
const CartFragment = gql`
  fragment CartFragment on Cart {
    id
    checkoutUrl
    cost {
      subtotalAmount {
        amount
      }
    }
    lines(first: 250) {
      edges {
        node {
          id
          merchandise {
            ... on ProductVariant {
              id
              title
              image {
                src
              }
              price {
                amount
              }
              selectedOptions {
                name
                value
              }
              weight
              product {
                title
              }
            }
          }
          quantity
        }
      }
    }
  }
`

export const createCart = gql`
  mutation cartCreate {
    cartCreate {
      userErrors {
        message
        field
      }
      cart {
        ...CartFragment
      }
    }
  }
  ${CartFragment}
`

export const cartLinesAdd = gql`
  mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      userErrors {
        message
        field
      }
      cart {
        ...CartFragment
      }
    }
  }
  ${CartFragment}
`

export const cartLinesUpdate = gql`
  mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      userErrors {
        message
        field
      }
      cart {
        ...CartFragment
      }
    }
  }
  ${CartFragment}
`

export const cartLinesRemove = gql`
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      userErrors {
        message
        field
      }
      cart {
        ...CartFragment
      }
    }
  }
  ${CartFragment}
`

export const checkoutCompleted = gql`
  query node($cartId: ID!) {
    node(id: $cartId) {
      ... on Cart {
        completedAt
      }
    }
  }
`

export function addVariantToCart(merchandiseId, quantity, cartNumber) {
  this.setState({ cartIsLoading: true })
  this.props
    .cartLinesAdd({
      variables: { cartId: this.state.cart.id, lines: [{ merchandiseId, quantity: parseInt(quantity, 10) }] },
    })
    .then(res => {
      this.setState({ cartIsLoading: false })
      this.setState(
        {
          cart: res.data.cartLinesAdd.cart,
        },
        () => {
          if (localStorage) {
            localStorage.setItem(cartNumber, JSON.stringify(this.state.cart))
          }
        }
      )
    })

  this.openCart()
}

export function updateLineItemInCart(lineItemId, quantity, cartNumber) {
  this.setState({ cartIsLoading: true })
  this.props
    .cartLinesUpdate({
      variables: {
        cartId: this.state.cart.id,
        lines: [{ id: lineItemId, quantity: parseInt(quantity, 10) }],
      },
    })
    .then(res => {
      this.setState({ cartIsLoading: false })
      if (res.data.cartLinesUpdate.cart != null) {
        this.setState(
          {
            cart: res.data.cartLinesUpdate.cart,
          },
          () => {
            if (localStorage) {
              localStorage.setItem(cartNumber, JSON.stringify(this.state.cart))
            }
          }
        )
      }
    })
}

export function removeLineItemInCart(lineItemId, cartNumber) {
  this.setState({ cartIsLoading: true })
  this.props.cartLinesRemove({ variables: { cartId: this.state.cart.id, lineIds: [lineItemId] } }).then(res => {
    this.setState({ cartIsLoading: false })
    if (res.data.cartLinesRemove.cart != null) {
      this.setState(
        {
          cart: res.data.cartLinesRemove.cart,
        },
        () => {
          if (localStorage) {
            localStorage.setItem(cartNumber, JSON.stringify(this.state.cart))
          }
        }
      )
    }
  })
}
