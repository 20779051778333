import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Store from './Store'

class StoreContainer extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query HeadingQuery {
            site {
              siteMetadata {
                cartNumber
              }
            }
          }
        `}
        render={data => (
          <Store
            // checkoutId="Z2lkOi8vc2hvcGlmeS9DaGVja291dC80MjIxYTM5NDA5OTI4ZDMxMTRmYjA0Zjc1NjY0MzJlNT9rZXk9YjIyOTVhM2Q3ZjA4YWI3ZmMxNGFmMDlmNjcxYWFkZWQ="
            cartNumber={data.site.siteMetadata.cartNumber}
          >
            {this.props.children}
          </Store>
        )}
      />
    )
  }
}

export default StoreContainer
