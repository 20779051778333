import React, { Component } from 'react'

import {
  createCart,
  checkoutCompleted,
  cartLinesAdd,
  cartLinesUpdate,
  cartLinesRemove,
  addVariantToCart,
  updateLineItemInCart,
  removeLineItemInCart,
} from '../apollo/checkout'
import { graphql, withApollo } from 'react-apollo'
import { flowRight as compose } from 'lodash'

import Layout from './Layout'

class Store extends Component {
  constructor() {
    super()

    this.state = {
      // isCartOpen: false,
      // isCustomerAuthOpen: false,
      // isNewCustomer: false,
      products: [],
      cart: { lines: { edges: [] } },
      cartIsHidden: true,
      cartIsLoading: false,
    }

    this.addVariantToCart = addVariantToCart.bind(this)
    this.removeLineItemInCart = removeLineItemInCart.bind(this)
    this.updateLineItemInCart = updateLineItemInCart.bind(this)
  }

  UNSAFE_componentWillMount() {
    const cartNumber = this.props.cartNumber

    if (typeof window !== 'undefined' && window.localStorage) {
      if (localStorage) {
        const cachedCart = localStorage.getItem(cartNumber)
        if (cachedCart && JSON.parse(cachedCart) != null) {
          this.setState({ cart: JSON.parse(cachedCart) })
        } else {
          localStorage.clear()
          this.props
            .createCart({
              variables: {
                cartInput: {},
              },
            })
            .then(res => {
              this.setState({
                cart: res.data.cartCreate.cart,
              })
            })
        }
      } else {
        this.props
          .createCart({
            variables: {
              cartInput: {},
            },
          })
          .then(res => {
            this.setState({
              cart: res.data.cartCreate.cart,
            })
          })
      }
    }
  }

  // componentDidMount() {
  //   if (this.state.cart && this.state.cart.id) {
  //     this.props.client
  //       .query({
  //         query: checkoutCompleted,
  //         variables: {
  //           cartId: this.state.cart.id,
  //         },
  //       })
  //       .then(res => {
  //         if (res.data.node.completedAt) {
  //           localStorage.clear()
  //           this.props
  //             .createCart({
  //               variables: {
  //                 cartInput: {},
  //               },
  //             })
  //             .then(res => {
  //               this.setState({
  //                 cart: res.data.cartCreate.cart,
  //               })
  //             })
  //         }
  //       })
  //   }
  // }

  // toggleCart = () => {
  //   this.setState((prevState) => {
  //     return { cartIsHidden: !prevState.cartIsHidden }
  //   })
  // }

  openCart = () => {
    this.setState({ cartIsHidden: false })
  }
  closeCart = () => {
    this.setState({ cartIsHidden: true })
  }

  render() {
    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        addVariantToCart: this.addVariantToCart,
        cart: this.state.cart,
        cartIsLoading: this.state.cartIsLoading,
      })
    })
    return (
      <Layout
        updateLineItemInCart={this.updateLineItemInCart}
        removeLineItemInCart={this.removeLineItemInCart}
        cart={this.state.cart}
        openCart={this.openCart}
        closeCart={this.closeCart}
        cartIsHidden={this.state.cartIsHidden}
        cartIsLoading={this.state.cartIsLoading}
      >
        {children}
      </Layout>
    )
  }
}

// export default Store

const StoreWithDataAndMutation = compose(
  // graphql(query),
  withApollo,
  // graphql(checkoutCompleted, { name: 'checkoutCompleted' }),
  graphql(createCart, { name: 'createCart' }),
  graphql(cartLinesAdd, { name: 'cartLinesAdd' }),
  graphql(cartLinesUpdate, { name: 'cartLinesUpdate' }),
  graphql(cartLinesRemove, { name: 'cartLinesRemove' })
  // graphql(checkoutCustomerAssociate, {name: "checkoutCustomerAssociate"})
)(Store)

export default StoreWithDataAndMutation
