import React, { Component } from 'react'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import theme from './styles/theme'
import { Transition } from 'react-spring'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'gatsby'
import Cookies from 'js-cookie'

import HelmetInfo from './HelmetInfo'
import Navbar from './Navbar'
import Footer from './Footer'
import Cart from './Cart'
import MobileNav from './MobileNav'
import StyledLink from './styles/StyledLink'
import Modal from './Modal'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Open Sans', sans-serif;
    color: ${p => p.theme.textColor};
    line-height: 1.5;
  }
  p {
    margin: 0;
    /* margin-bottom: 26px; */
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  ul {
    list-style: none;
    padding: 0;
    margin:0;
  }
  button {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
    color: inherit;
    font-family: 'Open Sans', sans-serif;
    color: ${p => p.theme.textColor};
  }
  strong {
    color: ${p => p.theme.textColorDark};
  }
  input {
    line-height: 1.2;
  }
  input[type='checkbox'] {
    opacity: 0;
  }
  ::placeholder {
    color: ${p => p.theme.midGray};
  }
  .no-scroll {
    overflow: hidden;
  }
  .js-focus-visible :focus:not(.focus-visible) {
  outline: none;
  }
  ::selection {
    background-color: ${p => p.theme.primaryColor};
    color: white;
  }
`

// For Sticky Footer
const Site = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`
const Content = styled.div`
  flex-grow: 1;
`

const CookieMessage = styled.div`
  @media (max-width: 416px) {
    margin-bottom: -10px;
  }
`

class Layout extends Component {
  state = {
    mobileNavIsHidden: true,
    showModal: false,
  }

  openModal = () => {
    this.setState({ showModal: true })
  }

  closeModal = () => {
    this.setState({ showModal: false })
    const modalCookie = Cookies.get('showModal')
    if (typeof modalCookie === 'undefined') {
      Cookies.set('showModal', 'false', { expires: 180 })
    }
  }

  componentDidMount() {
    const navbar = document.querySelector('.navbar')
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 0) {
        navbar.classList.add('navbar-not-at-top')
      }
      if (window.pageYOffset === 0) {
        navbar.classList.remove('navbar-not-at-top')
      }
    })

    const modalCookie = Cookies.get('showModal')
    if (typeof modalCookie === 'undefined') {
      setTimeout(() => {
        this.openModal()
      }, 25000)
    }
  }

  toggleMobileNav = () => {
    this.setState(prevState => {
      return { mobileNavIsHidden: !prevState.mobileNavIsHidden }
    })
    document.body.classList.toggle('no-scroll')
  }

  render() {
    const { cart, removeLineItemInCart, updateLineItemInCart, cartIsHidden, openCart, closeCart, cartIsLoading } =
      this.props
    return (
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <HelmetInfo />
          <GlobalStyle />
          <Site>
            <Navbar
              mobileNavIsHidden={this.state.mobileNavIsHidden}
              toggleMobileNav={this.toggleMobileNav}
              openCart={openCart}
              cart={cart}
            />
            <Content>{this.props.children}</Content>
            <Transition
              items={!cartIsHidden}
              from={{ transform: 'translate(415px)' }}
              enter={{ transform: 'translate(0px)' }}
              leave={{ transform: 'translate(415px)' }}
            >
              {(values, item) =>
                item && (
                  <Cart
                    style={values}
                    cart={cart}
                    removeLineItemInCart={removeLineItemInCart}
                    updateLineItemInCart={updateLineItemInCart}
                    closeCart={closeCart}
                    cartIsLoading={cartIsLoading}
                  />
                )
              }
            </Transition>
            <Transition
              config={{ duration: 100 }}
              items={!this.state.mobileNavIsHidden}
              from={{ opacity: '0' }}
              enter={{ opacity: '1' }}
              leave={{ opacity: '0' }}
            >
              {(values, item) =>
                item && (
                  <MobileNav
                    style={values}
                    mobileNavIsHidden={this.state.mobileNavIsHidden}
                    toggleMobileNav={this.toggleMobileNav}
                  />
                )
              }
            </Transition>

            <Footer />
            <CookieConsent
              style={{ background: 'rgba(255,255,255,0.93)', fontSize: '14px', color: '#484848' }}
              buttonText="OK"
              buttonStyle={{
                alignSelf: 'center',
                // backgroundColor: 'white',
                backgroundColor: '#EC7A5C',
                color: 'white',
                padding: '10px 20px',
                marginTop: '10px',
                marginBottom: '10px',
                marginRight: '14px',
                marginLeft: '14px',
                border: '1px solid var(--black-color)',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '13px',
                fontWeight: '600',
                transition: '0.15s ease-out',
              }}
            >
              <CookieMessage style={{ alignSelf: 'center' }}>
                Diese Website verwendet Cookies. Wenn Sie unsere Website nutzen, stimmen Sie der Verwendung von Cookies
                zu. Mehr Informationen finden Sie in unserer{' '}
                <StyledLink>
                  <Link to="/Datenschutzerklaerung/">Datenschutzerklärung</Link>
                </StyledLink>
                .
              </CookieMessage>
            </CookieConsent>
            {this.state.showModal && <Modal closeModal={this.closeModal} />}
          </Site>
        </React.Fragment>
      </ThemeProvider>
    )
  }
}

export default Layout
